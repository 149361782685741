export const home = () => ({
	type: 'HOME',
})

export const aboutMe = () => ({
	type: 'ABOUTME',
})

export const process = () => ({
	type: 'PROCESS',
})

export const resume = () => ({
	type: 'RESUME',
})

export const work = () => ({
	type: 'WORK',
})

// Projects

export const googleDesign = () => ({
	type: 'GOOGLEDESIGN',
})

export const americanMade = () => ({
	type: 'AMERICANMADE',
})

export const vai = () => ({
	type: 'VAI',
})

export const translator = () => ({
	type: 'TRANSLATOR',
})

export const jjMdc = () => ({
	type: 'JJMDC',
})

export const jjHome = () => ({
	type: 'JJHOME',
})

export const wrap1 = () => ({
	type: 'WRAP1',
})

export const wrap2 = () => ({
	type: 'WRAP2',
})

export const perforce1 = () => ({
	type: 'PERFORCE1',
})

export const perforce2 = () => ({
	type: 'PERFORCE2',
})

export const cisco = () => ({
	type: 'CISCO',
})

export const protohack = () => ({
	type: 'PROTOHACK',
})

