module.exports={
	// Google
	"Damien Correll": "//www.linkedin.com/in/damiencorrell",
	"Pat Iadanza": "//www.linkedin.com/in/patrickiadanza",
	"Carly Ayres": "//www.linkedin.com/in/carlyayres/",
	"Bryn Smith": "//www.linkedin.com/in/bryn-smith-0269244",
	"Sharon Correa Harris": "//www.instagram.com/sharonmharris/",
	"Clayton Meador": "//claytonmeador.com/",
	"Erin Kim": "//www.linkedin.com/in/erinykim",
	"Lauren Fox": "//laurenfox.work/",
	
	// J&J
	"Matthew Abate": "//www.linkedin.com/in/matthewabate",
	"Stephanie Skuzenski": "//www.linkedin.com/in/stephanieskuzenski",
	"Laura Hines": "//www.linkedin.com/in/laurahines22",
	"Jinny Kim": "//www.linkedin.com/in/jinny-kim-62997423",
	"Mark Patience": "//www.linkedin.com/in/markpatience",
	"Alex Gross": "//www.linkedin.com/in/alex-gross-668b2218/",
	"Chris Purcell": "//www.linkedin.com/in/christinepurcell",
	"Katrina Corcoran": "//www.linkedin.com/in/mkcorcoran/",
	"Howard Chambers": "//www.linkedin.com/in/hochambers",
	"Alisha Austin": "//www.linkedin.com/in/alisha-austin",
	"Kate Peoples": "//www.linkedin.com/in/kate-peoples-29557875",

	// WRAP
	"Pete Petras": "//www.linkedin.com/in/petepetras",
	"Josh Bloom": "//www.linkedin.com/in/jbloom", 
	"Jeff Klein": "//www.linkedin.com/in/jeffrklein/",
	"Mei Yeh": "//www.linkedin.com/in/mei-yeh-855a2311",
	"Theo Arguna": "//www.linkedin.com/in/theoarguna",
	"Cameron Myers": "//www.linkedin.com/in/cameronmyers1",

	// NBCU
	"Mina Azimov": "//www.linkedin.com/in/minaazimov",
	"Oleksandr Lebedyev": "//www.linkedin.com/in/oleksandr-lebedyev/",
	"Audrey Tse": "////www.linkedin.com/in/audreycintse",
	"Poplar Bai": "//www.linkedin.com/in/poplar-bai/",
	"Kennix Lee": "//www.linkedin.com/in/kennix-lee-b551308b",
	"Jing Zhao": "//www.linkedin.com/in/jing-zhao-ux-researcher",

	// PERFORCE
	"Janet Taylor": "//www.linkedin.com/in/janetktaylor",
	"Sean Ardley": "//www.linkedin.com/in/seanardley",
	"Rebecca Jablonski": "//www.linkedin.com/in/rebeccajablonsky",
	"David Taylor": "//www.linkedin.com/in/lifehug",
}