module.exports={
	"name": "All Pages",
	"items" : [
		{
			"name": "Home",
			"to": "/",
			"abbreviation": "H"
		},
		{
			"name": "Works",
			"to": "/work",
			"abbreviation": "W",
			"items": [
				{
					"name": "WeTransfer Studio",
					"items": [
						{
							"name": "Boucheron Quatre Ad", 
							"to": "//a.we.tl/?name=creator/boucheron/2401/stack-game/4_gCFrZk"
						},
						{
							"name": "Burberry FW22 Ad", 
							"to": "//a.we.tl/?name=creator/burberry/2209/reveal/1_CLRanK"
						},
						{
							"name": "Maker’s Mark 46 Ad", 
							"to": "//a.we.tl/?name=creator/makers-mark/2208/burn-reveal/3_cqKdZR"
						},
						{
							"name": "Tiffany 3D Ad", 
							"to": "//a.we.tl/?name=creator/tiffany-and-co/2212/tones-3d/10_L7wTHE"
						},
						{
							"name": "Gucci Experiment", 
							"to": "//a.we.tl/?name=creator/experiments/eric/draggable-image-stack/1_CxwXVg"
						}
					]
				},
				{
					"name": "Google Design",
					"items": [
						{
							"name": "Google Design", 
							"to": "/google-design",
							"abbreviation": "G",
							"media": {
								"src": "../assets/img/google-design/best-of-2020.gif",
								"type": "image",
								"aspectRatioWidth": 10,
								"aspectRatioHeight": 7
							}
						},
						{
							"name": "Is it Good Design?",
							"to": "//design.google/library/good-design",
							"media": {
								"src": "../assets/img/google-design/GoodDesign_logo.gif",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9
							}
						},
						{
							"name": "Best of 2019",
							"to": "//design.google/library/google-design-2019",
							"media": {
								"src": "../assets/img/google-design/BestOf2019-logo.gif",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9
							}
						},
						{ 
							"name": "Span 2019",
							"to": "//design.google/library/span2019",
							"media": {
								"type": "video",
								"src": "../assets/img/google-design/span-2019.mp4",
							}
						}
					]
				},
				{
					"name": "J&J Design Studio",
					"items": [
						{
							"name": "Home Intranet",
							"to": "/jnj-home",
							"abbreviation": "J1",
							"media": {
								"src": "../assets/img/jnj-home/onboarding-mobile-16x9.png",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9,
							}
						},
						{
							"name": "Medical Devices",
							"to": "/jnj-mdc",
							"abbreviation": "J2",
							"media": {
								"src": "../assets/img/jnj-mdc/ladies.jpg",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9,
							}
						}
					]
				},
				{
					"name": "NBCUX Lab",
					"items": [
						{
							"name": "American Made",
							"to": "/american-made",
							"abbreviation": "N1",
							"media": {
								"src": "../assets/img/american-made/columbia.gif",
								"type": "image",
								"aspectRatioWidth" : 16,
								"aspectRatioHeight" : 9,
							}
						},
						{
							"name": "Rationalized Player",
							"to": "/rationalized-player",
							"abbreviation": "N2",
							"media": {
								"src": "../assets/img/vai/mobile-16x9.jpg",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9,
							}
						},
						{
							"name": "Translator",
							"to": "/translator",
							"abbreviation": "N3",
							"media": {
								"src": "../assets/img/translator/mobile-16x9.jpg",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9,
							}
						},
						{ "name": "NBCUX Portfolio", "to": "//portfolio.nbcuxlab.com" },
						{ "name": "Release Notes", "to": "//release-notes.nbcuxlab.com/" },
					]
				},
				{
					"name": "Wrap Media",
					"items": [
						{
							"name": "Wrap Interactions",
							"to": "/micro-app-interactions",
							"abbreviation": "W1",
							"media": {
								"src": "../assets/img/card-components/share-animation-zoomed-out.gif",
								"type": "image",
								"aspectRatioWidth": 4,
								"aspectRatioHeight": 3,
							}
						},
						{
							"name": "Wrap Templates",
							"to": "/micro-app-templates",
							"abbreviation": "W2",
							"media": {
								"src": "../assets/img/card-components/perspective-cards.jpg",
								"type": "image",
								"aspectRatioWidth": 8,
								"aspectRatioHeight": 5,
							}
						},
					]
				},
				{
					"name": "Perforce",
					"items": [
						{
							"name": "Helix Sync",
							"to": "/helix-sync",
							"abbreviation": "P1",
							"media": {
								"src": "../assets/img/perforce/banner.jpg",
								"type": "image",
								"aspectRatioWidth": 5,
								"aspectRatioHeight": 4,
							}
						},
						{ 
							"name": "Helix Cloud",
							"abbreviation": "P2",
							"to": "/helix-cloud",
							"media": {
								"src": "../assets/img/perforce/helix-cloud-activity.jpg",
								"type": "image",
								"aspectRatioWidth": 16,
								"aspectRatioHeight": 9,
							}

						}
					]
				}
			]
		},
		{
			"name": "Process",
			"to": "/process",
			"abbreviation": "P",
		},
		{
			"name": "About Me",
			"to": "/about-me",
			"abbreviation": "A",
		},
		{
			"name": "Resume",
			"to": "/resume",
			"abbreviation": "R",
		}
	]
}
